/* eslint-disable no-nested-ternary */
// material-ui
import { useTheme } from '@mui/material/styles';
import { Badge, Box, Button, IconButton, Menu, Tooltip, Typography } from '@mui/material';
import { NotificationsNoneOutlined, TuneOutlined } from '@mui/icons-material';
import { FileMappedToNotification } from 'ui-component/records/types';
import { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';
import RelativeTime from 'dayjs/plugin/relativeTime';
import { FileNotification } from '../FileNotification';

export const NotificationButton = () => {
    dayjs.extend(CustomParseFormat);
    dayjs.extend(RelativeTime);

    const [anchorNotificationEl, setAnchorNotificationEl] = useState<null | HTMLElement>(null);
    const [inProgressDownloads, setInProgressDownloads] = useState<FileMappedToNotification[]>([]);

    const theme = useTheme();

    const handleNotificationClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorNotificationEl(event.currentTarget);

    const handleNotificationClose = () => setAnchorNotificationEl(null);

    const loadLocalStorageDownloads = useCallback(() => {
        setInProgressDownloads(
            JSON.parse(localStorage.getItem('bulk-download-data') ?? '[]').map((item: any) => ({
                ...item,
                started: dayjs(item.started, 'YYYY-MM-DD HH:mm')
            }))
        );
    }, []);

    const clearNewTagFromInProgressDownloads = () => {
        setInProgressDownloads(
            JSON.parse(localStorage.getItem('bulk-download-data') ?? '[]').map((item: any) => ({
                ...item,
                new: false,
                started: dayjs(item.started, 'YYYY-MM-DD HH:mm')
            }))
        );
        const newBulkDownload = JSON.parse(localStorage.getItem('bulk-download-data') ?? '[]').map((item: any) => ({
            ...item,
            new: false,
            started: dayjs(item.started, 'YYYY-MM-DD HH:mm')
        }));
        localStorage.setItem('bulk-download-data', JSON.stringify(newBulkDownload));
    };

    const clearNewTagFromInProgressDownload = (id: string) => {
        setInProgressDownloads(
            JSON.parse(localStorage.getItem('bulk-download-data') ?? '[]').map((item: any) =>
                item.id === id
                    ? {
                          ...item,
                          new: false,
                          started: dayjs(item.started, 'YYYY-MM-DD HH:mm')
                      }
                    : {
                          ...item,
                          started: dayjs(item.started, 'YYYY-MM-DD HH:mm')
                      }
            )
        );
        const newBulkDownload = JSON.parse(localStorage.getItem('bulk-download-data') ?? '[]').map((item: any) =>
            item.id === id
                ? {
                      ...item,
                      new: false,
                      started: dayjs(item.started, 'YYYY-MM-DD HH:mm')
                  }
                : {
                      ...item,
                      started: dayjs(item.started, 'YYYY-MM-DD HH:mm')
                  }
        );
        localStorage.setItem('bulk-download-data', JSON.stringify(newBulkDownload));
    };

    const handleClearDownload = (id: string) => {
        setInProgressDownloads(
            JSON.parse(localStorage.getItem('bulk-download-data') ?? '[]')
                .filter((item: any) => item.id !== id)
                .map((item: any) => ({
                    ...item,
                    started: dayjs(item.started, 'YYYY-MM-DD HH:mm')
                }))
        );
        const newBulkDownload = JSON.parse(localStorage.getItem('bulk-download-data') ?? '[]').filter((item: any) => item.id !== id);
        localStorage.setItem('bulk-download-data', JSON.stringify(newBulkDownload));
        handleNotificationClose();
    };

    useEffect(() => {
        loadLocalStorageDownloads();
        const onEvent = (ev: any) => {
            loadLocalStorageDownloads();
        };
        window.addEventListener('bulk-download', onEvent, false);
        return () => {
            window.addEventListener('bulk-download', onEvent, false);
        };
    }, [loadLocalStorageDownloads]);

    return (
        <>
            <Tooltip title="Notifications">
                <IconButton
                    onClick={(e) => {
                        inProgressDownloads.length > 0 && handleNotificationClick(e);
                    }}
                    size="large"
                    sx={{
                        backgroundColor: anchorNotificationEl ? theme.palette.secondary.light : theme.palette.primary[300],
                        marginRight: '0.5rem'
                    }}
                >
                    {inProgressDownloads.length > 0 && (
                        <Badge
                            badgeContent={inProgressDownloads.length}
                            sx={{
                                '&>span.MuiBadge-badge': {
                                    backgroundColor: 'rgb(255, 90, 95)',
                                    color: 'white'
                                }
                            }}
                        >
                            <NotificationsNoneOutlined
                                sx={{ color: anchorNotificationEl ? theme.palette.secondary.main : theme.palette.primary[400] }}
                            />
                        </Badge>
                    )}
                    {inProgressDownloads.length === 0 && <NotificationsNoneOutlined />}
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorNotificationEl}
                open={Boolean(anchorNotificationEl)}
                onClose={handleNotificationClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        minWidth: 540,
                        backgroundColor: '#F5F6F7',
                        borderRadius: '8px',
                        border: '1px solid #D9D9D9'
                    }
                }}
            >
                <Box
                    sx={{
                        borderBottom: '1px solid #D9D9D9',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingX: '8px'
                    }}
                >
                    <Typography
                        sx={{
                            mb: '5px',
                            color: '#000',
                            fontWeight: 600,
                            fontSize: '20px',
                            letterSpacing: '0.15px',
                            lineHeight: '32px'
                        }}
                    >
                        Notification
                    </Typography>
                    <TuneOutlined sx={{ color: theme.palette.secondary.main }} />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        width: '100%',
                        paddingRight: '1rem',
                        borderRadius: '15px'
                    }}
                >
                    <Button
                        onClick={() => {
                            clearNewTagFromInProgressDownloads();
                        }}
                        sx={{
                            padding: '0.5rem',
                            color: theme.palette.secondary.main,
                            textDecoration: 'underline',
                            textTransform: 'none'
                        }}
                        disableElevation
                        size="small"
                        variant="text"
                        color="inherit"
                    >
                        Mark all as read
                    </Button>
                </Box>
                {inProgressDownloads.map((download) => (
                    <FileNotification
                        key={download.id}
                        download={download}
                        handleClearDownload={handleClearDownload}
                        clearNewTagFromInProgressDownload={clearNewTagFromInProgressDownload}
                    />
                ))}
            </Menu>
        </>
    );
};
